import axios from 'axios'
import { useAuthStore } from '@/stores/authStore'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL
})

axiosInstance.interceptors.request.use((config) => {
  const authStore = useAuthStore()
  if (authStore.token) {
    config.headers.Authorization = `Bearer ${authStore.token}`
  }

  return config
})
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log(error.response)
    // error.response.
    if (error.response.status === 401) {
      const authStore = useAuthStore()
      await authStore.logout('/no-access')
    }
    return Promise.reject(error)
  }
)

export const useAxios = () => axiosInstance

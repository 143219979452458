<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useRouter } from 'vue-router'
import { faListCheck, faUsers } from '@fortawesome/free-solid-svg-icons'
import { useUserStore } from '@/stores/userStore.js'
import { computed } from 'vue'

const userStore = useUserStore()

const canSeeUsers = computed(() => {
  return userStore.user.permissions.SEE_USERS_SECTION
})
</script>

<template>
  <div class="w-full flex flex-wrap p-6">
    <div class="w-full">
      <img src="/assets/images/logo.png" alt="logo" class="w-3/12" />
    </div>
    <div class="w-full flex flex-wrap py-6">
      <Button as="router-link" to="/" severity="secondary" class="w-full text-left justify-start">
        <img src="/assets/icons/home.svg" alt="" />
        <span>Home</span>
      </Button>
    </div>
    <div class="w-full flex subtitle">
      <div class="text-muted-color text-xs z-50 bg-white pr-2">Manage</div>
    </div>
    <div class="w-full flex flex-wrap pt-6">
      <Button
        as="router-link"
        :to="{ name: 'benchmarking.list' }"
        severity="secondary"
        class="w-full text-left justify-start"
      >
        <img src="/assets/icons/sales.svg" alt="" />
        <span>Benchmarking</span>
      </Button>
    </div>
    <div class="w-full flex flex-wrap pt-2">
      <Button
        as="router-link"
        :to="{ name: 'members.list' }"
        severity="secondary"
        class="w-full text-left justify-start"
      >
        <img src="/assets/icons/sales.svg" alt="" />
        <span>Members</span>
      </Button>
    </div>
    <div class="w-full flex flex-wrap pt-2">
      <Button
        as="router-link"
        :to="{ name: 'groups.list' }"
        severity="secondary"
        class="w-full text-left justify-start"
      >
        <font-awesome-icon :icon="faUsers" />
        <span>Groups</span>
      </Button>
    </div>
    <div v-if="canSeeUsers" class="w-full flex flex-wrap pt-2">
      <Button
        as="router-link"
        :to="{ name: 'users.index' }"
        severity="secondary"
        class="w-full text-left justify-start"
      >
        <font-awesome-icon :icon="faUsers" />
        <span>Users</span>
      </Button>
    </div>
    <div v-if="canSeeUsers" class="w-full flex flex-wrap pt-2">
      <Button
        as="router-link"
        :to="{ name: 'notifications.list' }"
        severity="secondary"
        class="w-full text-left justify-start"
      >
        <font-awesome-icon :icon="faListCheck" />
        <span>Notifications</span>
      </Button>
    </div>
  </div>
</template>

<style scoped>
.subtitle {
  position: relative;

  &::after {
    content: ''; /* this is necessary for the pseudo-element to work */
    display: block;
    height: 2px; /* height of the bar */
    background-color: #f8fafc; /* color of the bar */
    position: absolute;
    /* start from the end of the text */
    top: 50%; /* align with the middle of the text */
    width: 100%; /* width of the bar */
  }
}
</style>

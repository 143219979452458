import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/pages/MainPage.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import { useApplicationService } from '@/services/applicationService.js'
import { reportType } from '@/enums/reportType.js'
import { faListCheck, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: MainLayout,
      beforeEnter: async (to, from) => {
        const applicationService = useApplicationService()
        return applicationService.updateAuthData(to)
      },
      children: [
        {
          path: '',
          name: 'dashboard',
          component: MainPage,
          meta: {
            title: 'Home',
            icon: 'home.svg'
          }
        },
        {
          path: 'users',
          name: 'users',
          meta: {
            title: 'Users',
            icon: faUsers
          },
          children: [
            {
              path: '',
              name: 'users.index',
              component: () => import('../pages/users/ListPage.vue')
            },
            {
              path: 'form/:id(\\d+)?',
              props: true,
              name: 'users.form',
              component: () => import('../pages/users/FormPage.vue'),
              meta: {
                hideHeader: true
              }
            }
          ]
        },
        {
          path: 'groups',
          name: 'groups',
          meta: {
            title: 'Groups',
            icon: faUsers
          },
          children: [
            {
              path: '',
              name: 'groups.list',
              component: () => import('../pages/groups/ListPage.vue')
            },
            {
              path: 'benchmarking',
              name: 'groups.benchmarking',
              component: () => import('../pages/groups/BenchmarkingPage.vue')
            }
          ]
        },
        {
          path: 'surveys',
          name: 'surveys',
          children: [
            {
              path: 'retail-monthly/export',
              name: 'surveys.retail-monthly.export',
              component: () => import('../pages/surveys/ExportPage.vue'),
              meta: {
                title: 'Retail Sales',
                icon: 'sales.svg',
                reportTemplateType: reportType.RETAIL_SALES_MONTHLY,
                hideHeader: true
              }
            },
            {
              path: 'retail-weekly/export',
              name: 'surveys.retail-weekly.export',
              component: () => import('../pages/surveys/ExportPage.vue'),
              meta: {
                title: 'RDF Weekly Retail Sales',
                icon: 'sales.svg',
                reportTemplateType: reportType.RETAIL_SALES_WEEKLY,
                hideHeader: true
              }
            },
            {
              path: 'grower/export',
              name: 'surveys.grower.export',
              component: () => import('../pages/surveys/ExportPage.vue'),
              meta: {
                title: 'Grower Sales',
                icon: 'sales.svg',
                reportTemplateType: reportType.GROWER_SALES,
                hideHeader: true
              }
            }
          ]
        },
        {
          path: 'notifications',
          name: 'notifications',
          meta: {
            title: 'Notifications',
            icon: faListCheck
          },
          children: [
            {
              path: '',
              name: 'notifications.list',
              component: () => import('../pages/notifications/ListPage.vue')
            },
            {
              path: 'show/:id(\\d+)?',
              props: true,
              name: 'notifications.form',
              component: () => import('../pages/notifications/NotificationPage.vue')
            }
          ]
        },
        {
          path: 'benchmarking',
          name: 'benchmarking',
          children: [
            {
              path: '',
              name: 'benchmarking.list',
              component: () => import('../pages/benchmarking/ListPage.vue'),
              meta: {
                title: 'Benchmarking',
                icon: 'home.svg'
              }
            },
            {
              path: 'survey',
              name: 'benchmarking.survey',
              children: [
                {
                  path: ':report_type',
                  name: 'benchmarking.survey.index',
                  component: () => import('../pages/benchmarking/SurveyPage.vue'),
                  meta: {
                    title: 'Survey',
                    icon: 'home.svg'
                  }
                },
                {
                  path: 'survey/:report_id/response/:account_id/sales',
                  name: 'benchmarking.survey.response.sales',
                  children: [
                    {
                      path: 'monthly',
                      name: 'benchmarking.survey.response.sales.monthly',
                      meta: {
                        title: 'Retail Sales',
                        icon: 'sales.svg',
                        reportTemplateType: reportType.RETAIL_SALES_MONTHLY
                      },
                      children: [
                        {
                          path: 'report',
                          name: 'benchmarking.survey.response.sales.monthly.report',
                          component: () => import('../pages/sales/LogPage.vue'),
                          meta: {
                            hideHeader: true
                          }
                        }
                      ]
                    },
                    {
                      path: 'weekly',
                      name: 'benchmarking.survey.response.sales.weekly',
                      meta: {
                        title: 'Retail Weekly Sales',
                        icon: 'sales.svg',
                        reportTemplateType: reportType.RETAIL_SALES_WEEKLY
                      },
                      children: [
                        {
                          path: 'report',
                          name: 'benchmarking.survey.response.sales.weekly.report',
                          component: () => import('../pages/sales/LogPage.vue'),
                          meta: {
                            hideHeader: true
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'survey/:report_id/response/:account_id/grower',
                  name: 'benchmarking.survey.response.grower',
                  children: [
                    {
                      path: 'monthly',
                      name: 'benchmarking.survey.response.grower.monthly',
                      meta: {
                        title: 'Grower Sales',
                        icon: 'sales.svg',
                        reportTemplateType: reportType.GROWER_SALES
                      },
                      children: [
                        {
                          path: 'report',
                          name: 'benchmarking.survey.response.grower.monthly.report',
                          component: () => import('../pages/sales/LogPage.vue'),
                          meta: {
                            hideHeader: true
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'members',
          name: 'members',
          children: [
            {
              path: '',
              name: 'members.list',
              component: () => import('../pages/members/ListPage.vue'),
              meta: {
                title: 'Members',
                icon: 'home.svg'
              }
            },
            {
              path: ':account_id',
              name: 'members.show',
              component: () => import('../pages/members/MemberPage.vue'),
              meta: {
                title: 'Member Profile',
                icon: 'home.svg'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../pages/LoginPage.vue'),
      beforeEnter: async (to) => {
        const applicationService = useApplicationService()
        return applicationService.updateAuthData(to)
      }
    }
  ]
})

export default router

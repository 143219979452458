import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import PrimeVue from 'primevue/config'
import htaPrimevuePreset from '@/htaPrimevuePreset'
import ToastService from 'primevue/toastservice'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import oidc from '@/plugins/oidc.js'

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(router)
app.use(oidc)
app.use(ToastService)

app.use(PrimeVue, {
  theme: {
    preset: htaPrimevuePreset,
    ripple: true,
    options: {
      prefix: 'p',
      darkModeSelector: '',
      cssLayer: {
        name: 'primevue',
        order: 'tailwind-base, primevue, tailwind-utilities'
      }
    }
  }
})

/*if (import.meta.env.VITE_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    app,
    env: import.meta.env.VITE_APP_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}*/

app.mount('#app')

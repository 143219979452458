<script setup>
import { useUserStore } from '@/stores/userStore.js'
import { computed } from 'vue'

const userStore = useUserStore()

const userInitials = computed(() => {
  return userStore.user.first_name[0] + userStore.user.last_name[0]
})
</script>

<template>
  <div class="w-full justify-center flex py-6 bg-surface-50 border-b">
    <div class="text-2xl w-10/12 font-semibold flex align-middle">
      <Avatar
        class="mr-4"
        :label="userInitials"
        shape="circle"
        style="background-color: #76ab16; color: #eeeeee"
      />
      Hey {{ userStore.user.first_name }}, welcome back
    </div>
  </div>
  <div class="w-full flex flex-wrap justify-center"></div>
</template>

<style scoped></style>

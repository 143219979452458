import { createOidc } from 'oidc-spa'

const realmId = import.meta.env.VITE_APP_KEYCLOAK_REALM
const realmUrl = `${import.meta.env.VITE_APP_KEYCLOAK_URL}/realms/${realmId}`
console.log(realmUrl)
const oidc = await createOidc({
  issuerUri: realmUrl,
  clientId: import.meta.env.VITE_APP_KEYCLOAK_CLIENT_ID,
  publicUrl: import.meta.env.BASE_URL
})

export const useOidc = () => oidc
